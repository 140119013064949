<template>
  <div>
    <b-card
      no-body
      class="p-3"
    >

      <validation-observer ref="protocolModelForm">
        <b-form @submit.prevent>

          <protocol-model-form />

          <b-row>
            <b-col class="mt-2">
              <b-button
                variant="outline-primary"
                :to="{ name: 'bariatric'}"
              >
                Cancelar
              </b-button>
              <b-button
                variant="primary"
                type="submit"
                class="ml-2"
                :disabled="buttonDisable"
                @click="onSubmit"
              >
                Cadastrar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>

    </b-card>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import {
  BCard, VBModal, BForm, BButton, BRow, BCol,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ProtocolModelForm from './ProtocolModel-form.vue'

export default {
  components: {
    BCard,
    BForm,
    BButton,
    BRow,
    BCol,

    ProtocolModelForm,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

    ValidationObserver,
  },

  directives: {
    'b-modal': VBModal,
  },

  data() {
    return {
      showModal: false,
      buttonDisable: false,
    }
  },

  methods: {
    onSubmit() {
      this.$refs.protocolModelForm.validate().then(success => {
        if (success) {
          this.buttonDisable = true
          const formName = this.$store.getters['protocolModel/protocolModelName']
          const formSpecialist = this.$store.getters['protocolModel/protocolModelSpecialist']
          const formExams = this.$store.getters['protocolModel/protocolModelExams']

          const data = { name: formName.name, exam_ids: formExams.selectedExam.map(e => e.id), health_specialist_ids: formSpecialist.selectedsHealthSpecialist.map(e => e.id) }

          this.$store.dispatch('protocolModel/create', { data })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Protocolo modelo cadastrado com sucesso!',
                  text: 'Você pode consultá-lo no menu “Protocolos modelo”.',
                  size: 'sm',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.$store.commit('survey/UPDATE_BARIATRIC_SURVEY', true)
              this.$router.push('/bariatric').catch(() => {})
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Erro ao cadastrar protocolo modelo',
                  text: 'Ops... Algo deu errado, tente novamente',
                  size: 'sm',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
            .finally(() => {
              this.buttonDisable = false
            })
        }
      })
    },
  },
}
</script>
